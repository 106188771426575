<template>
  <div class="page">
    <div class="topDiv">
      <Form inline>
        <FormItem class="formLabel"
          ><span>用户账号：</span>
          <i-input v-model="queryform.user_login_name" placeholder="请输入" class="iviewIptWidth250"></i-input>
        </FormItem>
        <FormItem class="formLabel">
          <span>用户名称：</span>
          <!-- <i-input v-model="queryform.user_name"
                 placeholder="请输入"
                 class="iviewIptWidth250"></i-input> -->
          <Select ref="name_select" placeholder="请选择" v-model="queryform.user_name" clearable filterable class="iviewIptWidth250">
            <Option v-for="item in UserNameList" :value="item.user_name" :key="item.user_id">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>所属角色：</span>
          <Select ref="name_select" placeholder="请选择" v-model="queryform.role_id" clearable filterable class="iviewIptWidth250" @on-change="belong">
            <Option v-for="(item, index) in selectRoleList" :value="item.id" :key="index">{{ item.role_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="search">查询</span>
          <span class="longTextBtn finger btnAdd marginLeft20" @click="addPersons" v-if="insertAble">新增用户</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">{{ item.name }}</li>
    </ul>
    <!-- 渲染table列表 -->
    <div class="tabelDiv">
      <Table :rowClass="rowClassName" highlight-row :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :loading="isLoad" :isLoad="isLoad" totalText="条记录"></Table>
    </div>
    <!-- <Table :columns="listColumns" :data="listData" border no-data-text="暂无产品">
      <template slot-scope="{ row, index }" slot="login_name">
        <div class="danjia">
          <span class="fl">¥ </span><i-input class="fl" :value="row.login_name" maxlength="8" placeholder="请输入" style="width: 100px;">{{ index }}</i-input>
        </div>
      </template>
    </Table> -->

    <!-- 点击新添加用户打开的对话框 -->
    <Modal width="626" v-model="createUser" label-position="left" :title="title" @on-cancel="cancel">
      <div v-if="createUser">
        <div class="addUser">
          <div class="clearfix">
            <div class="item">
              <span>用户账号</span>：
              <i-input style="width: 495px;" v-model="form.login_name" placeholder="请输入"></i-input>
            </div>
            <div class="item">
              <span>用户名称</span>：
              <i-input style="width: 495px;" v-model="form.user_name" placeholder="请输入"></i-input>
            </div>
            <div class="item">
              <span>用户描述</span>：
              <i-input style="width: 495px;" v-model="form.remark" placeholder="请输入"></i-input>
            </div>
            <div class="item">
              <span>职位</span>：
              <i-input style="width: 495px;" v-model="form.duty" placeholder="请输入"></i-input>
            </div>
            <div class="item">
              <span>电话</span>：
              <i-input style="width: 495px;" v-model="form.phone" placeholder="请输入"></i-input>
            </div>
            <div class="item">
              <span>邮件</span>：
              <i-input style="width: 495px;" v-model="form.email" placeholder="请输入"></i-input>
            </div>
            <div class="checkDiv">
              <span class="label">所属角色：</span>
              <CheckboxGroup v-model="form.role_id" @on-change="Rolechange" class="checkItem">
                <Checkbox class-name="checkbox" v-for="(item, index) in typeList" :key="index" :label="item.role_id"
                  ><span>{{ item.role_name }}</span></Checkbox
                >
              </CheckboxGroup>
            </div>
            <div class="checkDiv">
              <span class="label">可设置角色：</span>
              <CheckboxGroup v-model="form.role_list" @on-change="canSetRolechange($event)" class="checkItem">
                <Checkbox :disabled="IsSuplly" class-name="checkbox" v-for="(item, index) in typeList" :key="index" :label="item.role_id"
                  ><span class="text">{{ item.role_name }}</span></Checkbox
                >
              </CheckboxGroup>
            </div>
            <div class="checkDiv" v-show="iscompanyList">
              <span class="label">所属仓库：</span>
              <!--  @on-change="selectAll" -->
              <CheckboxGroup @on-change="CheckboxChange" v-model="form.warehouse_id" class="checkItem">
                <Checkbox label="-1" class-name="checkbox"><span class="text">全选</span></Checkbox>
                <Checkbox class-name="checkbox" v-for="(item, index) in cityList" :key="index" :label="item.warehouse_id"
                  ><span class="text">{{ item.warehouse_name }}</span></Checkbox
                >
              </CheckboxGroup>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button v-show="title == '新增'" @click="okAddUser()" type="primary">确定</Button>
        <Button v-show="title == '修改'" @click="Updata()" type="primary">确定</Button>
      </div>
    </Modal>
    <!-- 删除模态框 -->
    <Modal v-model="deleteState" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认删除？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureDetele">确定</span>
          <span class="pageBtn finger btnCancle" @click="deleteState = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 启用账号 -->
    <Modal v-model="editS" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否{{ statustitle }}该用户？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="editStatus">确定</span>
          <span class="pageBtn finger btnCancle" @click="editS = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Table from '@/components/table'
export default {
  components: {
    Table,
  },
  watch: {
    role_id() {},
  },
  data() {
    return {
      editS: false,
      statustitle: '',
      navFrist: [
        {
          name: '职员用户',
          num: 140,
          type: 1,
        },
        {
          name: '供应商用户',
          num: 40,
          type: 2,
        },
        {
          name: '客户用户',
          num: 40,
          type: 3,
        },
      ],
      clickIndex: 1,
      AllwarehouseIdList: [],
      isCheckAll: false,
      deleteState: false,
      IsSuplly: false,
      UserNameList: [],
      // 添加新用户所绑定的数据
      form: {
        user_id: '',
        login_name: '', // 添加用户必填
        user_name: '',
        remark: '',
        duty: '',
        phone: '',
        email: '',
        role_id: [], // 添加用户必填
        role_list: [],
        warehouse_id: [],
        dealer_id: '1', //
      },
      str: '',
      // 中间数组
      setrole: '',
      // 存储数据
      newrow: {},
      isLoad: true,
      title: '',
      // 查询输入框中的数据绑定
      queryform: {
        user_login_name: '',
        user_name: '',
        role_id: null,
      },
      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },
      // table表单的数据存储
      listData: [],
      // table表单的 标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
          indexMethod: row => {
            let label = (this.pages.page - 1) * 10 + row._index + 1
            return label
          },
        },
        {
          title: '用户账号',
          key: 'login_name',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.login_name
              ),
            ])
          },
        },
        {
          title: '用户名称',
          key: 'user_name',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.user_name
              ),
            ])
          },
        },
        {
          title: '所属角色',
          key: 'belong_role',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.belong_role
              ),
            ])
          },
        },
        {
          title: '可设置角色',
          key: 'can_set_role',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                param.row.can_set_role
              ),
            ])
          },
        },
        {
          title: '所属仓库',
          key: 'warehouse',
          align: 'center',
          render: (h, param) => {
            if (!param.row.belong_role.includes('供应商')) {
              return h('div', [
                h('span', {
                  class: 'tableBlakcFont',
                }),
                param.row.warehouse,
              ])
            } else {
              return h('div', [
                h('span', {
                  class: 'tableBlakcFont',
                }),
              ])
            }
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, param) => {
            if (param.row.login_name === 'admin') {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableBlakcFont',
                    on: {
                      click: () => {},
                    },
                  },
                  ''
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    // class: 'tableFont',
                    class: {
                      tableFont: true,
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.change(param.row)
                      },
                    },
                  },
                  '修改'
                ),
                h(
                  'span',
                  {
                    // class: 'tableFont tableLeft',
                    class: {
                      tableFont: true,
                      tableLeft: true,
                      disableUse: this.modifyAble,
                      disHidden: param.row.state == 1 ? true : false,
                    },
                    on: {
                      click: () => {
                        this.statustitle = '启用'
                        this.editS = true
                        this.row = param.row
                      },
                    },
                  },
                  '启用'
                ),
                h(
                  'span',
                  {
                    // class: 'tableFont tableLeft',
                    class: {
                      tableFont: true,
                      tableLeft: true,
                      disableUse: this.modifyAble,
                      disHidden: param.row.state !== 1 ? true : false,
                    },
                    on: {
                      click: () => {
                        this.statustitle = '禁用'
                        this.editS = true
                        this.row = param.row
                      },
                    },
                  },
                  '禁用'
                ),
                h(
                  'span',
                  {
                    // class: 'tableFont tableLeft',
                    class: {
                      tableFont: true,
                      tableLeft: true,
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    // class: 'tableFont tableLeft',
                    class: {
                      tableFont: true,
                      tableLeft: true,
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.reset(param.row.user_id)
                      },
                    },
                  },
                  '重置密码'
                ),
              ])
            }
          },
        },
      ],
      // createUser 控制修改按钮
      createUser: false,
      // 控制所属角色复选框
      typeList: [],
      role_id: [],
      // 接口原始数组
      checkLisk: [],
      role_list: [],
      spinStatu: false, // 加载中
      levelList: [1, 2, 3, 4],
      // 用来存储被删除的id
      deleteId: '',
      resetState: false,
      resetId: '',
      // 控制公司下拉框
      companyList: [],
      cityList: [],
      // 所属公司所选值数 //默认传递1
      defaultare: '',
      // 控制是否 选择了供应商   选择了就为false
      iscompanyList: true,
      selectRoleList: [],
      hadSelectList: [],
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
      hadSelect: [],
      row: {},
    }
  },
  created() {
    this.queryList()
    this.queryAllList()
    this.queryTopList()
    this.getwarehouse()
    this.getRightVisibal()
  },
  methods: {
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.state == -1) return 'bgredRow'
      return ''
    },
    // 状态改变
    editStatus() {
      if (this.row.state == 1) {
        this.row.state = -1
      } else {
        this.row.state = 1
      }
      this.$http.put(this.$api.templateDownloadCg, { user_id: this.row.user_id, state: this.row.state }, true).then(res => {
        this.editS = false
        this.queryList()
      })
    },
    clickNav(type) {
      this.pages.page = 1
      this.pages.rows = 10
      this.clickIndex = type
      this.queryList()
      this.queryTopList()
      this.$http.get(this.$api.permissionQueryUser, { page: 1, rows: 99999, tab: this.clickIndex }, true).then(res => {
        this.UserNameList = res.data
      })
    },
    selectAll(e) {
      // console.log(e);
      // console.log(e.indexOf('-1'))
      // console.log(this.cityList);
      // if(e){
      //   this.form.warehouse_id=this.AllwarehouseIdList
      // }else{
      //   this.form.warehouse_id=[]
      // }
      // console.log(this.form.warehouse_id);
    },
    CheckboxChange(e) {
      console.log(e)
      let lastCheck = e[e.length - 1]
      console.log(lastCheck)
      // 直接最后勾选的全部
      // 如果lastCheck= '-1' 就点击了全选
      if (lastCheck == '-1') {
        // 如果点击了全选选项
        this.form.warehouse_id = this.AllwarehouseIdList
      } else if (e.indexOf('-1') != -1 && e.length != this.AllwarehouseIdList.length) {
        // 如果 点击后数组中存在'-1', 就是取消了单独一个选项  并且e的长度不等于全选的长度  就去掉数组中的-1
        let index = e.indexOf('-1')
        this.form.warehouse_id.splice(index, 1)
      } else if (e.indexOf('-1') < 0 && e.length == this.checkLisk.length && this.hadSelectList.indexOf('-1') < 0) {
        // 如果 点击后数组中不存在'-1'  并且e的长度等于全部数组的长度  并且上一次保存中不存在-1
        // 向开头添加一个'-1'
        this.form.warehouse_id.unshift('-1')
      } else if (e.indexOf('-1') < 0 && this.hadSelectList.indexOf('-1') >= 0) {
        // 如果 点击后数组中不存在'-1'  并且上一次保存中中存在-1
        // 就赋值空
        this.form.warehouse_id = []
      }
      this.form.warehouse_id = this.form.warehouse_id.length > 0 ? JSON.parse(JSON.stringify(this.form.warehouse_id)) : []
      this.hadSelectList = this.form.warehouse_id.length > 0 ? JSON.parse(JSON.stringify(this.form.warehouse_id)) : []
    },
    // 获取所属角色
    queryTopList() {
      this.$http.get(this.$api.permissionRoleList, { page: 1, rows: 1000 }, false).then(res => {
        this.selectRoleList = res.data
      })
    },
    // 翻页
    changePage(e) {
      this.pages.page = e
      this.queryList()
    },
    // 重制密码
    reset(id) {
      let data = {
        user_id: id,
      }
      this.$http.put(this.$api.passwordReset, data, true).then(res => {
        this.$Message.success(res.message)
      })
    },
    // 获取用户数据
    queryList() {
      this.isLoad = true
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        tab: this.clickIndex,
      }
      this.$http.get(this.$api.permissionQueryUser, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
      })
    },
    // 获取全部数据
    queryAllList() {
      this.$http.get(this.$api.permissionQueryUser, { page: this.pages.page, rows: 99999, tab: this.clickIndex }, true).then(res => {
        this.UserNameList = res.data
      })
    },
    // 修改所属角色触发事件
    Rolechange(e) {
      console.log(e, 'e')
      console.log(this.form.role_id)
      // 如果只选择了供应商
      if (e.length == 1 && e[0] == 9) {
        this.iscompanyList = false
        this.IsSuplly = true
        this.form.role_list = []
        this.form.warehouse_id = [1]
      }
      if (e.length == 0) {
        console.log('空数组')
        this.iscompanyList = true
        this.IsSuplly = false
      }
      if (e.length > 1) {
        this.iscompanyList = true
        this.IsSuplly = false
      }
    },
    // 修改可设置角色触发时间
    canSetRolechange(e) {
      console.log(e)
      console.log(this.form.role_list)
      console.log(this.typeList, '可设置角色')
    },
    // 获取仓库列表
    getwarehouse() {
      let data = {
        page: 1,
        rows: 999,
      }
      // 获取仓库列表数据
      this.$http.get(this.$api.permissionWareHouseList, data, true).then(res => {
        console.log(res)
        this.cityList = res.data
      })
    },

    // 点击查询
    search() {
      this.pages.page = 1
      this.pages.rows = 10
      // 添加查询属性
      let data = {
        user_login_name: this.queryform.user_login_name,
        user_name: this.queryform.user_name,
        role_id: this.queryform.role_id,
        page: this.pages.page,
        rows: this.pages.rows,
        tab: this.clickIndex,
      }
      //
      console.log(data)
      // 查询数据
      this.$http.get(this.$api.permissionQueryUser, data, true).then(res => {
        console.log(res)
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
      })
    },

    // 点击新增用户 按钮触发事件
    addPersons() {
      // 将标题改为title
      this.title = '新增'
      // 重制form
      this.form = {
        user_id: '',
        login_name: '', // 添加用户必填
        user_name: '',
        remark: '',
        duty: '',
        phone: '',
        email: '',
        role_id: [], // 添加用户必填
        role_list: [],
        warehouse_id: [],
        dealer_id: '1', //
      }
      // 发送请求获取用户可选角色
      this.$http
        .get(this.$api.permissionGetUsersRoles, false)
        .then(res => {
          console.log(res)
          this.typeList = res.data
        })
        .then(res => {
          let data = {
            page: 1,
            rows: 9999,
          }
          // 获取仓库列表数据
          this.$http.get(this.$api.permissionWareHouseList, data, true).then(res => {
            this.cityList = res.data
            this.AllwarehouseIdList = []
            this.checkLisk = []
            this.cityList.forEach((item, index) => {
              this.checkLisk.push(item.warehouse_id)
            })
            //  把全部 代表的 -1 也传进去
            this.AllwarehouseIdList = ['-1', ...this.checkLisk]
            this.createUser = true
            //   console.log(this.cityList)
          })
        })
    },
    // 点击 添加用户的确定
    okAddUser() {
      // 这里填写新增用户提交的数据
      if (!this.form.login_name.trim()) {
        this.$Message.warning('请填写登录账号后再提交')
        return
      }
      if (!this.form.user_name.trim()) {
        this.$Message.warning('请填写用户名称后再提交')
        return
      }
      if (this.form.role_id.length == 0) {
        this.$Message.warning('请选择所属角色后再提交')
        return
      }
      let Index = this.form.warehouse_id.indexOf('-1')
      // 如果全选了
      if (Index !== -1) {
        this.form.warehouse_id.splice(Index, 1)
      }
      let data = {
        login_name: this.form.login_name,
        user_name: this.form.user_name,
        dealer_id: '1',
        remark: this.form.remark,
        role_id: this.form.role_id,
        role_list: this.form.role_list,
        duty: this.form.duty,
        phone: this.form.duty,
        email: this.form.email,
        warehouse_id: this.form.warehouse_id,
      }
      // 如果为false 被隐藏了
      if (this.iscompanyList == false) {
        data.warehouse_id = [1]
      }
      console.log(data)
      // 添加用户
      this.$http
        .post(this.$api.permissionUser, data, true)
        .then(res => {
          this.$Message.success('新建用户成功')
          this.pages.page = 1
          this.queryList()
          this.createUser = false
          this.iscompanyList = true
          this.form.warehouse_id = []
          this.hadSelectList = []
        })
        .then(res => {
          this.$http.get(this.$api.permissionQueryUser, { page: 1, rows: 99999, tab: this.clickIndex }, true).then(res => {
            this.UserNameList = res.data
          })
        })
    },
    belong(e) {
      console.log(e)
    },

    // 删除
    deleteRow(row) {
      this.deleteState = true
      this.deleteId = row.user_id
    },
    // 点击删除框确认按钮触发事件
    sureDetele() {
      this.$http
        .Delete(this.$api.permissionUser, this.deleteId, true)
        .then(res => {
          this.deleteState = false
          this.pages.page = 1
          this.queryList()
        })
        .then(res => {
          this.$http.get(this.$api.permissionQueryUser, { page: 1, rows: 99999, tab: this.clickIndex }, true).then(res => {
            this.UserNameList = res.data
          })
        })
    },

    // Table中 点击修改 触发事件
    change(row) {
      this.title = '修改'
      // console.log(row)
      // 发送请求获取用户可选角色
      this.$http.get(this.$api.permissionGetUsersRoles, false).then(res => {
        this.typeList = res.data
      })
      let data = {
        page: 1,
        rows: 999,
      }
      // 获取仓库列表数据
      this.$http
        .get(this.$api.permissionWareHouseList, data, true)
        .then(res => {
          this.cityList = res.data
          this.AllwarehouseIdList = []
          this.checkLisk = []
          this.cityList.forEach((item, index) => {
            this.checkLisk.push(item.warehouse_id)
          })
          //  把全部 代表的 -1 也传进去
          this.AllwarehouseIdList = ['-1', ...this.checkLisk]
          this.createUser = true
          //   console.log(this.cityList)
        })
        .then(res => {
          console.log(row)
          // 深拷贝对象 row
          this.newrow = JSON.parse(JSON.stringify(row))
          this.newrow.role_id = this.newrow.belong_role.split(',')
          this.newrow.role_list = this.newrow.can_set_role.split(',')

          // 不需要的属性
          delete this.newrow.can_set_role
          delete this.newrow.belong_role
          delete this.newrow._index
          delete this.newrow._rowKey
          delete this.newrow.warehouse

          // 字符串转换为数字
          this.newrow.warehouse_id = JSON.parse(this.newrow.warehouse_id)
          console.log(this.newrow.warehouse_id)
          console.log(this.AllwarehouseIdList)
          if (this.newrow.warehouse_id.length === this.AllwarehouseIdList.length - 1) {
            this.newrow.warehouse_id.unshift('-1')
          }
          this.hadSelectList = this.newrow.warehouse_id
          // this.newrow.warehouse_id = Number(this.newrow.warehouse_id)
          // 通过名称将 角色名称转换为 数字
          let arry1 = []
          this.newrow.role_id.forEach((item, index) => {
            this.typeList.forEach((currentitem, currentindex) => {
              if (currentitem.role_name == item) {
                arry1.push(currentitem.role_id)
              }
            })
          })

          let arry2 = []
          this.newrow.role_list.forEach((item, index) => {
            this.typeList.forEach((currentitem, currentindex) => {
              if (currentitem.role_name == item) {
                arry2.push(currentitem.role_id)
              }
            })
          })

          this.newrow.role_id = arry1
          this.newrow.role_list = arry2
          // 将字符串 warehouse_id变为数组
          this.form = this.newrow
          // 将this.form 再次深拷贝
          this.form = JSON.parse(JSON.stringify(this.form))
          console.log(this.form)
          if (this.form.role_id.length === 1 && this.form.role_id[0] == 9) {
            this.iscompanyList = false
          }
          this.createUser = true
        })
    },
    // 点击修改   按钮进行修改
    Updata() {
      // 这里填写新增用户提交的数据
      if (!this.form.login_name.trim()) {
        this.$Message.warning('请填写登录账号后再提交')
        return
      }
      if (!this.form.user_name.trim()) {
        this.$Message.warning('请填写用户名称后再提交')
        return
      }
      if (this.form.role_id.length == 0) {
        this.$Message.warning('请选择所属角色后再提交')
        return
      }
      //  if (this.form.role_list.length == 0) {
      //   this.$Message.warning('请选择所属角色后再提交')
      //   return
      // }
      let data = this.form
      // 如果为false 就代表选择了供应商 id穿1
      if (this.iscompanyList == false) {
        data.warehouse_id = [1]
      }
      console.log(this.form.warehouse_id)
      let Index = this.form.warehouse_id.indexOf('-1')
      if (Index !== -1) {
        this.form.warehouse_id.splice(Index, 1)
      }
      console.log(data)
      // 发送修改信息请求
      this.$http
        .Put(this.$api.permissionUser, data.user_id, data, true)
        .then(res => {
          // console.log(res)
          this.queryList()
          this.form.warehouse_id = [1]
          this.hadSelectList = []
          this.createUser = false
        })
        .then(res => {
          this.queryAllList()
        })
    },
    cancel() {
      this.hadSelectList = []
      this.form.warehouse_id = [1]
      this.iscompanyList = true
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      console.log(this.$route)
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>
<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }

  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.checkbox {
  margin-right: 20px;
  margin-bottom: 100px;
}
/deep/ .ivu-input {
  height: 36px;
  line-height: 36px;
}
/deep/ .ivu-select-selection {
  height: 36px;
  line-height: 36px;
}
.formLabel {
  margin-right: 50px;
  span {
    color: #000;
  }
}
.ivu-checkbox-group-item {
  display: inline-block;
  margin-bottom: 22px;
  margin-right: 16px;
}
.addUser {
  span {
    color: #000;
    font-size: 16px;
  }
  .Row {
    margin-bottom: 22px;
    .InputName {
      color: #000;
      font-size: 16px;
    }
  }
  .box {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  span {
    width: 90px;
    color: #000;
    text-align: justify;
    height: 36px;
    line-height: 36px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.ivu-radio-wrapper {
  margin-bottom: 20px !important;
  margin-right: 10px !important;
}
.ivu-checkbox-group-item {
  margin-bottom: 20px !important;
  margin-right: 10px !important;
}
.checkDiv {
  display: flex;
  .label {
    display: inline-block;
    width: 104px;
    color: #000;
    text-align: justify;
    height: 36px;
    line-height: 36px;
  }
  .label::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
  .checkItem {
    flex: 1;
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
/deep/ .disHidden {
  display: none !important;
}
</style>
